import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import fetchForm from "../../../helpers/fetchForm";
import HoneyPot from "../../../components/HoneyPot/HoneyPot";

const EstimateSchema = Yup.object().shape({
  "your-name": Yup.string().required("The field is required"),
  "your-message": Yup.string().required("The field is required"),
  "email-754": Yup.string()
    .email("The e-mail address is invalid.")
    .required("The field is required"),
  "tel-241": Yup.string()
    .matches(/.*[0-9].*/, "The telephone number is invalid.")
    .matches(/^[^a-zA-Z]+$/, "The telephone number is invalid.")
});

const EstimateForm = () => {
  const [message, setMessage] = useState({ type: "", message: "" });

  return (
    <div>
      <Formik
        initialValues={{
          "your-name": "",
          "text-501": "",
          "email-754": "",
          "tel-241": "",
          "your-message": "",
          "checkbox-226[]": "Integrations",
          _wpcf7: "351",
          _wpcf7_version: "5.1.1",
          _wpcf7_locale: "en_US",
          _wpcf7_unit_tag: "wpcf7-f351-p53-o1",
          _wpcf7_container_post: "53"
        }}
        validationSchema={EstimateSchema}
        onSubmit={(values, { resetForm }) => {
          const termsValue = document.getElementById("terms").checked;
          if (termsValue === true) return;
          // eslint-disable-next-line no-undef
          const url = `${REACT_APP_API_DOMAIN}wp-json/contact-form-7/v1/contact-forms/351/feedback`;
          fetchForm(values, url, resetForm).then(r => {
            setMessage(r);
          });
        }}
      >
        {({ errors, touched }) => (
          <Form className="wpcf7-form">
            <div className="form__input form__input-estimate">
              <div className="form__input-estimate--half">
                {/* eslint-disable jsx-a11y/label-has-for */}
                <label htmlFor="your-name" id="firstNameFieldLabel">
                  First Name<span>*</span>
                  <span className="wpcf7-form-control-wrap your-name">
                    <Field
                      name="your-name"
                      id="your-name"
                      type="text"
                      size="40"
                      aria-required="true"
                      className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                    />
                    {errors["your-name"] && touched["your-name"] ? (
                      <span role="alert" className="wpcf7-not-valid-tip">
                        {errors["your-name"]}
                      </span>
                    ) : null}
                  </span>
                </label>
                {/* eslint-enable jsx-a11y/label-has-for */}
              </div>
              <div className="form__input-estimate--half">
                {/* eslint-disable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
                <label htmlFor="text-501" id="lastNameFieldLabel">
                  Last Name
                  <span className="wpcf7-form-control-wrap text-501">
                    <Field
                      name="text-501"
                      id="text-501"
                      type="text"
                      size="40"
                      aria-required="false"
                      className="wpcf7-form-control wpcf7-text"
                    />
                  </span>
                </label>
                {/* eslint-enable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
              </div>
            </div>
            <div className="form__input form__input-estimate">
              <div className="form__input-estimate--half">
                {/* eslint-disable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
                <label htmlFor="email-754" id="emailFieldLabel">
                  E-mail<span>*</span>
                  <span className="wpcf7-form-control-wrap email-754">
                    <Field
                      name="email-754"
                      id="email-754"
                      type="email"
                      size="40"
                      aria-required="true"
                      className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                    />
                    {errors["email-754"] && touched["email-754"] ? (
                      <span role="alert" className="wpcf7-not-valid-tip">
                        {errors["email-754"]}
                      </span>
                    ) : null}
                  </span>
                </label>
                {/* eslint-enable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
              </div>
              <div className="form__input-estimate--half">
                {/* eslint-disable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
                <label htmlFor="tel-241" id="phoneFieldLabel">
                  Phone
                  <span className="wpcf7-form-control-wrap tel-241">
                    <Field
                      name="tel-241"
                      id="tel-241"
                      type="text"
                      size="40"
                      aria-required="false"
                      className="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-tel"
                    />
                    {errors["tel-241"] && touched["tel-241"] ? (
                      <span role="alert" className="wpcf7-not-valid-tip">
                        {errors["tel-241"]}
                      </span>
                    ) : null}
                  </span>
                </label>
                {/* eslint-enable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
              </div>
            </div>
            <div className="form__input">
              {/* eslint-disable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
              <label htmlFor="your-message" id="messageFieldLabel">
                How we can help you?<span>*</span>
                <span className="wpcf7-form-control-wrap your-message">
                  <Field
                    component="textarea"
                    name="your-message"
                    id="your-message"
                    size="40"
                    aria-required="true"
                    className="pcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                  />
                  {errors["your-message"] && touched["your-message"] ? (
                    <span role="alert" className="wpcf7-not-valid-tip">
                      {errors["your-message"]}
                    </span>
                  ) : null}
                </span>
              </label>
              {/* eslint-enable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
            </div>
            <HoneyPot />
            <div className="estimate-button">
              <input
                type="submit"
                value="Estimate"
                className="wpcf7-form-control wpcf7-submit btn__cta btn__cta--green btn--estimate"
              />
              <span className="ajax-loader" />
            </div>
            <div
              className={`wpcf7-response-output wpcf7-display-none ${
                message.type
              } ${message.type === "wpcf7-mail-sent-ok" && "mail-estimate-sent"}`}
            >
              {message.message}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EstimateForm;
