import React from "react";
import renderHTML from "../../helpers/renderHTML";

const EstimateServicesBoxes = ({ allServices }) => {
  return (
    <>
      {allServices.edges.map(item => {
        return (
          <li className="service__select--clickable" data-checkbox="1">
            <div
              aria-hidden
              role="button"
              onClick={e => {
                e.currentTarget.parentElement.classList.toggle("selected");
              }}
              onFocus={() => {}}
            >
              <figure className="image">
                <img
                  className="service-icon responsive-img"
                  src={item.node.acf.service_icon.source_url}
                  alt={item.node.id}
                />
                <figcaption dangerouslySetInnerHTML={renderHTML(item.node.title)} />
              </figure>
              <div className="corners" />
              <div className="top-left-overlays" />
              <div className="bottom-right-overlays" />
              <div className="bottom-right-overlays__shadows" />
              <div className="shadow" />
              <div className="selected-icon">
                <div className="hex">
                  <div className="hex inner">
                    <span />
                  </div>
                </div>
              </div>
            </div>
          </li>
        );
      })}
    </>
  );
};

export default EstimateServicesBoxes;
