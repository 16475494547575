import React, { PureComponent } from "react";
import { graphql } from "gatsby";

import Layout from "../Layout/Layout";
import EstimateServicesBoxes from "../modules/EstimateServices/EstimateServices";
import renderHTML from "../helpers/renderHTML";
import SEO from "../Layout/Seo";

import EstimateForm from "../modules/Estimate/components/EstimateForm";

class PageEstimateTemplate extends PureComponent {
  render() {
    const currentPage = this.props.data.wordpressPage;
    const allServices = this.props.data.allWordpressWpServices;

    return (
      <Layout>
        <SEO
          title={currentPage.yoast.title ? currentPage.yoast.title : currentPage.title}
          keywords={currentPage.yoast.metakeywords}
          description={currentPage.yoast.metadesc}
        />
        <div className="estimate-wrapper">
          <div className="section__header">
            <h2
              className="section__title--large"
              dangerouslySetInnerHTML={renderHTML(currentPage.title)}
            />
            <h5 className="section__title--subtitle">
              Fill out this simple form. Our team will contact you promptly to discuss next steps.
            </h5>
          </div>
          <section className="estimate">
            <div className="container">
              <section className="estimate__section services">
                <div className="content-description">
                  <div className="hex">
                    <div className="hex inner">
                      <span className="num">01.</span>
                    </div>
                  </div>
                  <span className="content-description__title">
                    What kind of services do you need?
                  </span>
                </div>
                <div className="services__select">
                  <ul>
                    <EstimateServicesBoxes allServices={allServices} />
                  </ul>
                </div>
              </section>
            </div>
            <div className="horizontal-line" />
            <div className="container">
              <section className="estimate__section form">
                <div className="content-description">
                  <div className="hex">
                    <div className="hex inner">
                      <span className="num">02.</span>
                    </div>
                  </div>
                  <span className="content-description__title">
                    Fill the form and get
                    <br />
                    an estimate!
                  </span>
                </div>

                <div className="form__content">
                  <EstimateForm />
                </div>
              </section>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default PageEstimateTemplate;

export const pageQuery = graphql`
  query currentEstimatePageMainQuery($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      slug
      id
      yoast {
        title
        metakeywords
        metadesc
      }
    }
    allWordpressWpServices {
      edges {
        node {
          id
          title
          acf {
            service_icon {
              source_url
            }
          }
        }
      }
    }
  }
`;
